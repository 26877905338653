<template>

  <div v-if="isLoading==true">
    <img src="@/assets/loading.gif">
  </div>
    <div v-if="facility!=null">{{ 
    facility?.name
    }}</div>
      <div v-if="error!=null">{{ 
    error
    }}</div>
</template>

<script setup>
import api from '@/Services/api';

import {  onBeforeMount, ref,defineProps } from 'vue';

let facility = ref(null);
let isLoading = ref(null);
let error = ref(null);
const props = defineProps({
  code: String
})



onBeforeMount(async () => {
    await fetchFacility(); // <div>
})

async function fetchFacility() {
  isLoading.value = true;
    try {
      const result = await api.getFacility(props.code);
      facility.value = result.data;
      
      error.value = null;  // Reset error state
    } catch (err) {
      
      error.value = "can't load";  // Store error message
    }
    finally {
      isLoading.value = false;
    }
  }

</script>