<template>

    <div v-if="isLoading==true">
      <img src="@/assets/loading.gif">
    </div>
      <div v-if="product!=null">{{ 
      product?.product_title
      }}</div>
        <div v-if="error!=null">{{ 
      error
      }}</div>
  </template>
  
  <script setup>
  import api from '@/Services/api';
  
  import {  onBeforeMount, ref,defineProps } from 'vue';
  
  let product = ref(null);
  let isLoading = ref(null);
  let error = ref(null);
  const props = defineProps({
    id: String
  })
  
  
  
  onBeforeMount(async () => {
      await fetchProduct(); // <div>
  })
  
  async function fetchProduct() {
    isLoading.value = true;
      try {
        const result = await api.getProduct(props.id);
        product.value = result;
        
        error.value = null;  // Reset error state
      } catch (err) {
        
        error.value = "can't load";  // Store error message
      }
      finally {
        isLoading.value = false;
      }
    }
  
  </script>