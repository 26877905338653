import axios from 'axios';


const apiClient = axios.create({
  baseURL: 'https://api.darcom.ps/api/v1/clients',
  headers: {
    'Content-Type': 'application/json',
    'Version':'1.0.1'
  },
});

// Interceptor to include Bearer token
apiClient.interceptors.request.use((config) => {
    return config;
  }, (error) => {
    return Promise.reject(error);
  });


const handleResponse = (response) => response.data;

const handleError = (error) => {
    if (error.response) {
      // Request was made and server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response error:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Request error:', error.request);
      return Promise.reject('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error:', error.message);
      return Promise.reject(error.message);
    }
  };

export default {
  getFacility(code) {
        return apiClient.get(`facilities/facility/${code}`,{}).then(handleResponse).catch(handleError);
  },
  getProduct(id) {
    return apiClient.get(`/products/product/${id}`,{}).then(handleResponse).catch(handleError);
  },

  // Add more methods as needed
};