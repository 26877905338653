import { createWebHistory, createRouter } from 'vue-router'

import HomeView from './Modules/HomeView.vue'
import FacilitiesRouter from './Modules/FacilitiesView.vue'
import ProductsRouter  from './Modules/ProductsView.vue'

import AndroidClientPrivacyRouter  from './Modules/Pages/Android/Client/ClientPrivacyView.vue'
import AndroidClientTermsRouter  from './Modules/Pages/Android/Client/ClientTermsView.vue'
import AndroidClientDeletionRouter  from './Modules/Pages/Android/Client/ClientDeletionView.vue'
import AndroidBusinessPrivacyRouter  from './Modules/Pages/Android/Business/BusinessPrivacyView.vue'
import AndroidBusinessTermsRouter  from './Modules/Pages/Android/Business/BusinessTermsView.vue'
import AndroidBusinessDeletionRouter  from './Modules/Pages/Android/Business/BusinessDeletionView.vue'

import AppleClientPrivacyRouter  from './Modules/Pages/Apple/Client/ClientPrivacyView.vue'
import AppleClientTermsRouter  from './Modules/Pages/Apple/Client/ClientTermsView.vue'
import AppleBusinessPrivacyRouter  from './Modules/Pages/Apple/Business/BusinessPrivacyView.vue'
import AppleBusinessTermsRouter  from './Modules/Pages/Apple/Business/BusinessTermsView.vue'

import DownloadRouter  from './Modules/DownloadView.vue'
import BusinessDownloadRouter  from './Modules/BusinessDownloadView.vue'


const routes = [
  { path: '/', component: HomeView },
  { path: '/f/:code', component: FacilitiesRouter, props: true },
  { path: '/products/:id', component: ProductsRouter , props: true},

  { path: '/android/client/privacy.html', component: AndroidClientPrivacyRouter },
  { path: '/android/client/terms.html', component: AndroidClientTermsRouter },
  { path: '/android/client/deletion.html', component: AndroidClientDeletionRouter },
  { path: '/android/business/privacy.html', component: AndroidBusinessPrivacyRouter },
  { path: '/android/business/terms.html', component: AndroidBusinessTermsRouter },
  { path: '/android/business/deletion.html', component: AndroidBusinessDeletionRouter },

  { path: '/apple/client/privacy.html', component: AppleClientPrivacyRouter },
  { path: '/apple/client/terms.html', component: AppleClientTermsRouter },
  { path: '/apple/business/privacy.html', component: AppleBusinessPrivacyRouter },
  { path: '/apple/business/terms.html', component: AppleBusinessTermsRouter },

  { path: '/download', component: DownloadRouter },
  { path: '/business_download', component: BusinessDownloadRouter },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router