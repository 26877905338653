<template>
    <div><img src="@/assets/darcom-cover.png"></div>
</template>
<style scoped>

div {
    display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
}
img{
    text-align: center;  
    margin: auto;
    display: block;
}
</style>