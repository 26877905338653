<template>
    <div>
      <p>Redirecting you to download our app...</p>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      this.redirectToAppStore();
    },
    methods: {
      redirectToAppStore() {
        // Check if the browser supports the user-agent client hints API
        if ('userAgentData' in navigator) {
          this.redirectUsingClientHints();
        } else {
          this.redirectUsingUserAgent();
        }
      },
      async redirectUsingClientHints() {
        try {
          const userAgentData = await navigator.userAgentData.getHighEntropyValues(['platform']);
          const platform = userAgentData.platform.toLowerCase();
          
          if (platform.includes('android')) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.darcom.darcom_client';
          } else if (platform.includes('ios')) {
            window.location.href = 'https://apps.apple.com/app/id6648766729';
          } else {
            this.showFallbackMessage();
          }
        } catch (error) {
          console.error('Error getting user agent data:', error);
          this.redirectUsingUserAgent();
        }
      },
      redirectUsingUserAgent() {
        const userAgent = navigator.userAgent.toLowerCase();
        
        if (userAgent.includes('android')) {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.darcom.darcom_client';
        } else if (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream) {
          window.location.href = 'https://apps.apple.com/app/id6648766729';
        } else {
          this.showFallbackMessage();
        }
      },
      showFallbackMessage() {
        alert('Please visit our website to download the app for your device.');
      }
    }
  }
  </script>